import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/coqui-ai.github.io/coqui-ai.github.io/src/templates/NewsletterTemplate.tsx";
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query($fileAbsolutePath: String) {
    ...SidebarPageFragment
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "934px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "27.599999999999998%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAA3XAAAN1wFCKJt4AAABCElEQVQY042RQUsCcRDF/VB9g+59gk6eOhTRpaBbh5CgYCuiTkYGah6CSIwiyiRbiiyVFg+5EoS6LO1/XS0oXffXriC1atCDOcyDeW/mTQAXjuMwhFHcH/g9H/CaPqG/W5T0V1ShUTbqjDIb7L/abR/vE8y8KEjyMfv5NHuFS87kO0LhCBvxBOuxBPHTc0K7EVJZGV2YhI+SZAvF3qzd7f4I9h287WpNgfho8WzWWdjaZnFzh+DSMtMra0zMzTO7KjE2GewJj0/NcOKKe+jY9vCGWsvkvlom55ZqamhvBqnrG26fFDIPeYTVRIoecHiR5ir3iKJWMBqW/+TBbDynz46by/9/4sv1G2shujMHTg19AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png",
          "srcSet": ["/static/c9103bc33c8add5ab1fa4fa1c49c90ef/43fa5/logo-wordmark.png 250w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/c6e3d/logo-wordmark.png 500w", "/static/c9103bc33c8add5ab1fa4fa1c49c90ef/ca463/logo-wordmark.png 934w"],
          "sizes": "(max-width: 934px) 100vw, 934px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "work-at-coqui",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#work-at-coqui",
        "aria-label": "work at coqui permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👩‍💻Work at Coqui`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`Yeah, you heard that right; we’re hiring!`}</p>
    <p>{`An open source remote-friendly Berlin based startup founded by the creators of Mozilla’s
`}<a parentName="p" {...{
        "href": "https://github.com/mozilla/tts"
      }}>{`text-to-speech`}</a>{` (TTS) and `}<a parentName="p" {...{
        "href": "https://github.com/mozilla/deepspeech"
      }}>{`speech-to-text`}</a>{`
(STT) engines (over `}<a parentName="p" {...{
        "href": "https://somsubhra.github.io/github-release-stats/?username=mozilla&repository=deepspeech&page=1&per_page=300"
      }}>{`550K downloads`}</a>{`
and 22K GitHub stars), with the backing of investors from around the globe (London,
San Francisco, and Berlin), `}<em parentName="p">{`and`}</em>{` we’re hiring!`}</p>
    <p>{`What’s not to love?`}</p>
    <p>{`We’re hiring across-the-board for a number of roles; so, there’s something for everyone:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/job/head-of-product"
        }}>{`Head of Product`}</a></li>
      <li parentName="ul">{`3 x `}<a parentName="li" {...{
          "href": "/job/senior-full-stack-engineer"
        }}>{`Senior Full Stack Engineers`}</a></li>
      <li parentName="ul">{`2 x `}<a parentName="li" {...{
          "href": "/job/senior-stt-deep-learning-engineer"
        }}>{`Senior STT Deep Learning Engineers`}</a></li>
      <li parentName="ul">{`2 x `}<a parentName="li" {...{
          "href": "/job/senior-tts-deep-learning-engineer"
        }}>{`Senior TTS Deep Learning Engineers`}</a></li>
      <li parentName="ul">{`2 x `}<a parentName="li" {...{
          "href": "/job/senior-developer-community-manager"
        }}>{`Senior, Developer Community Managers`}</a></li>
    </ul>
    <p>{`The full list of open positions is available on our `}<a parentName="p" {...{
        "href": "/jobs"
      }}>{`jobs page`}</a>{`.`}</p>
    <p>{`We’d love to hear from you; so, if any roles pique your interest, reach out to
`}<a parentName="p" {...{
        "href": "mailto:jobs@coqui.ai"
      }}>{`jobs@coqui.ai`}</a>{`. 🐸!`}</p>
    <h3 {...{
      "id": "welcome",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#welcome",
        "aria-label": "welcome permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`👩‍🍳Welcome`}</h3>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/kdavis-coqui"
      }}>{`Kelly Davis`}</a></p>
    <p>{`This month we’ve cooked up a few tasty nuggets for you!`}</p>
    <ul>
      <li parentName="ul">{`To make training STT models even easier, we’ve brewed up two Jupyter notebooks to help with the process, a
`}<a parentName="li" {...{
          "href": "https://colab.research.google.com/github/coqui-ai/STT/blob/main/notebooks/train-your-first-coqui-STT-model.ipynb"
        }}>{`Colab notebook`}</a>{`
to train from scratch and a second `}<a parentName="li" {...{
          "href": "https://colab.research.google.com/github/coqui-ai/STT/blob/main/notebooks/easy-transfer-learning.ipynb"
        }}>{`Colab notebook`}</a>{`
to fine-tune with.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://translatorswithoutborders.org/"
        }}>{`Translators without Borders`}</a>{` baked you a Bengali speech-to-text
model that they generously released in the `}<a parentName="li" {...{
          "href": "https://coqui.ai/bengali/twb/v0.1.0"
        }}>{`Model Zoo`}</a>{`.`}</li>
      <li parentName="ul">{`Also, stars of stage and (the small) screen, we’ve stewed up a `}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=2Fw3OxQrLUo"
        }}>{`little video`}</a>{`
on our joint work with Harvard and Google.`}</li>
      <li parentName="ul">{`TTS is heating up with the release of TTS `}<a parentName="li" {...{
          "href": "https://github.com/coqui-ai/TTS/releases/tag/v0.1.3"
        }}>{`v0.1.3`}</a>{`,
the latest and greatest version of our text-to-speech engine.`}</li>
    </ul>
    <p>{`Details below the fold; enjoy the newsletter!`}</p>
    <h3 {...{
      "id": "jupyter-notebooks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#jupyter-notebooks",
        "aria-label": "jupyter notebooks permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`📔Jupyter Notebooks`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "76.4%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/2e3f39b04829dbfdd47a65c99ddce146/da8b6/jupyter_notebooks.png",
          "srcSet": ["/static/2e3f39b04829dbfdd47a65c99ddce146/43fa5/jupyter_notebooks.png 250w", "/static/2e3f39b04829dbfdd47a65c99ddce146/c6e3d/jupyter_notebooks.png 500w", "/static/2e3f39b04829dbfdd47a65c99ddce146/da8b6/jupyter_notebooks.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`Like Coqui? Like Python notebooks? You’re going to ❤️ this 😎.`}</p>
    <p>{`We’ve added support for Jupyter notebooks in Coqui STT; so, you can train models easily from your browser!
Check them out on Google Colab:`}</p>
    <ol>
      <li parentName="ol">{`Train a new model from scratch `}<a parentName="li" {...{
          "href": "https://colab.research.google.com/github/coqui-ai/STT/blob/main/notebooks/train-your-first-coqui-STT-model.ipynb"
        }}>{`Open In Colab`}</a></li>
      <li parentName="ol">{`Train a model with transfer learning `}<a parentName="li" {...{
          "href": "https://colab.research.google.com/github/coqui-ai/STT/blob/main/notebooks/easy-transfer-learning.ipynb"
        }}>{`Open In Colab`}</a></li>
    </ol>
    <p>{`If you want to run notebooks on your own machine, we also created a
`}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/STT/blob/main/Dockerfile.train.jupyter"
      }}>{`Coqui+Jupyter Dockerfile`}</a>{` to make
things easier.`}</p>
    <h3 {...{
      "id": "-translators-without-borders-deploying-coquis-stt",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-translators-without-borders-deploying-coquis-stt",
        "aria-label": " translators without borders deploying coquis stt permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🚀 Translators without Borders Deploying Coqui’s STT`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "26.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/6e86e7167e88292ae0d167d54b53efff/da8b6/translators_without_borders.png",
          "srcSet": ["/static/6e86e7167e88292ae0d167d54b53efff/43fa5/translators_without_borders.png 250w", "/static/6e86e7167e88292ae0d167d54b53efff/c6e3d/translators_without_borders.png 500w", "/static/6e86e7167e88292ae0d167d54b53efff/da8b6/translators_without_borders.png 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`“Creating a world where knowledge knows no language barriers” - that’s the mission statement of
`}<a parentName="p" {...{
        "href": "https://translatorswithoutborders.org/"
      }}>{`Translators without Borders`}</a>{` (TWB).`}</p>
    <p>{`We’re happy to share that 🐸 STT is becoming a part of voice-enabled applications at TWB, starting
with speech-to-text for Bengali! TWB trained a Bengali speech-to-text model, and they generously
released the model in the `}<a parentName="p" {...{
        "href": "https://coqui.ai/bengali/twb/v0.1.0"
      }}>{`Model Zoo`}</a>{`.`}</p>
    <p>{`This Bengali model is becoming part of TWB’s online glossary application for the Bengali language,
which will enable faster search and translation.`}</p>
    <p><a parentName="p" {...{
        "href": "https://alpoktem.github.io/"
      }}>{`Alp Öktem`}</a>{` (Computational Linguist at TWB and co-founder of
`}<a parentName="p" {...{
        "href": "https://collectivat.cat/en"
      }}>{`Col·lectivaT`}</a>{`) trained the Bengali model using 🐸 STT in a little
under 10 days using about 200 hours of open-source Bengali data.`}</p>
    <p>{`Data formatting took up the bulk of development time, but once the data was formatted, training an
STT model was “pretty straightforward” , says Öktem. Following the recommendations from a recent
`}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/2105.04674"
      }}>{`Coqui publication`}</a>{`, Öktem found that fine-tuning from a
pre-trained 🐸 STT model improved performance drastically compared to training from scratch.`}</p>
    <p>{`Keep an eye out for new models and new voice-enabled applications from TWB!`}</p>
    <h3 {...{
      "id": "coqui-stars-of-stage-and-the-small-screen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#coqui-stars-of-stage-and-the-small-screen",
        "aria-label": "coqui stars of stage and the small screen permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🎥Coqui, Stars of Stage and (the Small) Screen`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=2Fw3OxQrLUo"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1000px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.4%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAC/ElEQVQoz42S30+TVxzGX6GIJLuYZYoQfgwXoDGTxASjiQ7NsptlyTZ2t2RRnBp3YeLfsES91MTERBlBb7wwGbQrpT8oLQivbZFSrBVHCRbaF2pbWqB9+SHD9uN5KzPZ3U7y5Pt8n5Pz5Pmec6R0Ok0mk2FlZYXV1dX/QtNyOdKxGF79fmRpD+b9R+mtbMN+8CS2qpNYK49jqTqB/MkxZiUDEv9nqeu8OvAZ05LEg/YObn39MwNnfmHg+ytYv7tE37e/4qn7hjXpGFI+n6dQKJD/F6L/WLU94bezliVYUcGkMHTqqujX1WAtrcZWUYd9Xy2W8lqRvpKIgFTYDaGZFnbNNRQ10WtrZ3mZhR9+JH7tKpHOc8x3nmfx8iWWLl5GuXCR6LlOlN+uEPmpQxiKQ++yWVSfj5zHQ3Z0lJzXizo+jurxfkgaf0PkUTeO8DTe1wEm3oRYeJskk1eZzc4RUxeJb7wl+URG2hGXnpPlouG6389mMMjW9HSRa+bqxATbs6+Jdt/mRVBm7qWTuCKTSQZZioeRx13MzU+xlIiTHLCLhNqIu2NvbG6SEq++nM6wvrHx8U3+WUqS6LpJQuljfvIB84EekrMWtjNrRPx/Ehi+QzYVQHWLhFkxbk6kVFW1+H3+Dod5NTNDKpUqaurWFisi4dzdGwSe9+FxP2TM1UP4hYW15RShKS9P3TaisQXiNidSTImhKArKosKMMAuFXgqECAsei0ZREgmRapJn13/H/9SFx2rEYzcxNeLALzt49mSIgGuMSdmH/959JLdjkBG7g1GXG4/Pi9nt5LHRhNk+yPCIj7FRN8MWC+bGw5haDJgOf8FfGv+8EWNDA8b6elHrRC94TTWSzdyPtd+CTRxyDQ1hG3TQazJhFJpzcAiHw461t4+H+k/pKS2hp7yMblGL0Gko5Y/SPdwvkegSmtTaepSv2ts5dfo0Tc3NNDc1YTAYMLS00CS4ph1va6P10CHq9+6lobycWp3uA8rKqC4p4Yhez9nGRr6s1PMeDcXTSKspB/4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMAGE",
            "title": "IMAGE",
            "src": "/static/ae30ec0103b3e08341ddd8884af31821/da8b6/stars_of_stage-and_screen.png",
            "srcSet": ["/static/ae30ec0103b3e08341ddd8884af31821/43fa5/stars_of_stage-and_screen.png 250w", "/static/ae30ec0103b3e08341ddd8884af31821/c6e3d/stars_of_stage-and_screen.png 500w", "/static/ae30ec0103b3e08341ddd8884af31821/da8b6/stars_of_stage-and_screen.png 1000w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span></a></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/JRMeyer"
      }}>{`Josh Meyer`}</a></p>
    <p>{`Previously, we let you all know about our research collaboration with Harvard and Google.
Now we have a `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=2Fw3OxQrLUo"
      }}>{`3-minute video overview`}</a>{` of
that project. The video was created with ❤️ by `}<a parentName="p" {...{
        "href": "https://markmaz.com/"
      }}>{`Mark Mazmuder`}</a>{` from
the Harvard Edge Computing Lab.`}</p>
    <p>{`We hope you find the video accessible and interesting; and that it gives you a better
idea of how we’re bringing speech technologies to all languages!`}</p>
    <h3 {...{
      "id": "out-the-door-tts-v013",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#out-the-door-tts-v013",
        "aria-label": "out the door tts v013 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`Out the Door, TTS `}<a parentName="h3" {...{
        "href": "https://github.com/coqui-ai/TTS/releases/tag/v0.1.3"
      }}>{`v0.1.3`}</a></h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQGAQP/xAAWAQEBAQAAAAAAAAAAAAAAAAAGBAX/2gAMAwEAAhADEAAAAX1ucmRTVxhLqf/EABkQAAMBAQEAAAAAAAAAAAAAAAMEBQIBIv/aAAgBAQABBQLPOMGVXC1o/glXpVk5Vgm7z9Pax//EABwRAAICAgMAAAAAAAAAAAAAAAECACESMSJR8P/aAAgBAwEBPwFODqrDHdDXqhJ7n//EAB4RAQABAwUBAAAAAAAAAAAAAAECAAMREiFBYbHh/9oACAECAQE/AWI2pysuQxvz37RqT61//8QAIhAAAgEEAgEFAAAAAAAAAAAAAQIDABESIQQxIiNBUWJx/9oACAEBAAY/ArxZABdtPpVH52aCxWSaLd3Hi9/kURLxuRl9DkKl5gl3Enpi3RrBjksyBDlWAQde1f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExUWFxkaHB/9oACAEBAAE/IdgmzaraPZ9GQJACHkHZe8kR+FPwn7vESWgGoyvq4dQxwLZb85JjBy1n/9oADAMBAAIAAwAAABC4/wD/xAAaEQEAAgMBAAAAAAAAAAAAAAABETEAUWGB/9oACAEDAQE/EGhqpBZIXvG3wTDn/8QAGxEBAAICAwAAAAAAAAAAAAAAAREhAIExcfD/2gAIAQIBAT8Qt8xKbAwvkjpW1ETxtz//xAAdEAEBAAMBAAMBAAAAAAAAAAABEQAhMUFhcYGR/9oACAEBAAE/EOPe1WRKjODEa3JPcYFeVAEOhBKMQQrykpBca06APcRkiFCLNGH5b9YR6aKQA4EP8+cWkFIu2xdl3L+5/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg",
          "srcSet": ["/static/1e77dc6fce06f5a577ab0e4d0674e779/0988f/release.jpg 250w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/d1f95/release.jpg 500w", "/static/1e77dc6fce06f5a577ab0e4d0674e779/dbdff/release.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`This new version fixes important issues with the new TrainerAPI and introduces various
performance improvements. Also, there is a new FullbandMelGAN vocoder released for the
Thorsten German dataset. It achieves faster than real-time inference with the Tacotron2
DDC model.`}</p>
    <p>{`Try out the German model:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`pip install TTS
tts --model_name tts_models/de/thorsten/tacotron2-DCA \\
    --text "Was geschehen ist geschehen, es ist geschichte."
`}</code></pre>
    <p>{`Thanks to all the contributors who helped for this release:`}</p>
    <ul>
      <li parentName="ul">{`👑 `}<a parentName="li" {...{
          "href": "https://github.com/WeberJulian"
        }}>{`@WeberJulian`}</a></li>
      <li parentName="ul">{`👑 `}<a parentName="li" {...{
          "href": "https://github.com/Edresson"
        }}>{`@Edresson`}</a></li>
      <li parentName="ul">{`👑 `}<a parentName="li" {...{
          "href": "https://github.com/thorstenMueller"
        }}>{`@thorstenMueller`}</a></li>
    </ul>
    <h3 {...{
      "id": "-monthly-tts-papers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#-monthly-tts-papers",
        "aria-label": " monthly tts papers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "width": "16",
          "height": "16",
          "focusable": "false",
          "viewBox": "0 0 16 16"
        }}>{`
  `}<path parentName="svg" {...{
            "fill": "currentColor",
            "d": "M4.441 7.38l.095.083.939.939-.708.707-.939-.939-2 2-.132.142a2.829 2.829 0 003.99 3.99l.142-.132 2-2-.939-.939.707-.708.94.94a1 1 0 01.083 1.32l-.083.094-2 2A3.828 3.828 0 01.972 9.621l.15-.158 2-2A1 1 0 014.34 7.31l.101.07zm7.413-3.234a.5.5 0 01.057.638l-.057.07-7 7a.5.5 0 01-.765-.638l.057-.07 7-7a.5.5 0 01.708 0zm3.023-3.025a3.829 3.829 0 01.15 5.257l-.15.158-2 2a1 1 0 01-1.32.083l-.094-.083-.94-.94.708-.707.939.94 2-2 .132-.142a2.829 2.829 0 00-3.99-3.99l-.142.131-2 2 .939.939-.707.708-.94-.94a1 1 0 01-.082-1.32l.083-.094 2-2a3.828 3.828 0 015.414 0z"
          }}></path>
        </svg></a>{`🔬 Monthly TTS Papers`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.8%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAG8fTjqGJiV/8QAGhAAAgMBAQAAAAAAAAAAAAAAAAECAxEhIv/aAAgBAQABBQKjsYYnqRTLzWzT/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAgMAAAAAAAAAAAAAAAAAABABETH/2gAIAQEABj8CKULD/8QAHRAAAgIBBQAAAAAAAAAAAAAAAREAITEQUXGhsf/aAAgBAQABPyHIa9jhO4KABXMVUMCFR30P/9oADAMBAAIAAwAAABCMz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAQEAAgMAAwAAAAAAAAAAAAERACExQWFRgfD/2gAIAQEAAT8QE79Ycp9ayZ+Irb85V2jkm+5fmpmEui4RejKd/pkoKGl5c//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMAGE",
          "title": "IMAGE",
          "src": "/static/ee7ae83975f5a8e36a44fa9649c1ad37/dbdff/monthly-papers.jpg",
          "srcSet": ["/static/ee7ae83975f5a8e36a44fa9649c1ad37/0988f/monthly-papers.jpg 250w", "/static/ee7ae83975f5a8e36a44fa9649c1ad37/d1f95/monthly-papers.jpg 500w", "/static/ee7ae83975f5a8e36a44fa9649c1ad37/dbdff/monthly-papers.jpg 1000w"],
          "sizes": "(max-width: 1000px) 100vw, 1000px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`By `}<a parentName="p" {...{
        "href": "https://github.com/erogol"
      }}>{`Eren Gölge`}</a></p>
    <p>{`We’ve also read some really interesting TTS papers this month. A few which were of particular interest:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/abs/2106.06103"
        }}>{`VITS: Conditional Variational Autoencoder with Adversarial Learning for End-to-End Text-to-Speech`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/abs/2006.06873"
        }}>{`FastPitch: Parallel Text-to-speech with Pitch Prediction`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://arxiv.org/pdf/2106.08962.pdf"
        }}>{`Efficient Deep Learning: A Survey on Making Deep Learning Models Smaller, Faster, and Better`}</a></li>
    </ul>
    <p><strong parentName="p">{`Note`}</strong>{`: VITS and FastPitch models will be available in the next TTS release with pre-trained models. 🚀`}</p>
    <p>{`👀 See our `}<a parentName="p" {...{
        "href": "https://github.com/coqui-ai/TTS-papers"
      }}>{`TTS-papers`}</a>{` list for even more TTS papers!`}</p>
    {
      /* markdownlint-enable line-length */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      